<template>
  <div>
    <breadCrumb title="Privacy Policy" :menuList="menuList"></breadCrumb>

    <section class="mb-4">
      <div class="container">
        <div class="p-4 bg-white rounded shadow-sm overflow-hidden mw-100 text-left">
          <template v-if="content.length > 0">
            <div v-html="content"></div>
          </template>
          <template v-else>
            <h1>{{ appName }} Privacy Policy</h1>
            <p>
              {{ appName }} is committed to protecting your privacy. We have developed this Privacy Policy to help you understand how we collect, use, share, and protect your personal information.
            </p>
            <h2>1. Collection and Use of Personal Information</h2>
            <p>
              When you use {{ appName }}, we may collect the following personal information from you:
            </p>
            <ul>
              <li>
                <b>Account information</b>: This includes your name, email address, password, and contact information.
              </li>
              <li>
                <b>Device information</b>: This includes your IP address, device type, operating system version, and device settings.
              </li>
              <li>
                <b>Usage information</b>: This includes the pages you visit on {{ appName }}, the links you click, and the features you use.
              </li>
              <li>
                <b>Other information</b>: This includes any other information you provide to us, such as your comments and feedback on {{ appName }}.
              </li>
            </ul>
            <p>
              We will use your personal information for the following purposes:
            </p>
            <ul>
              <li>
                <b>To provide our services</b>: We use your personal information to provide and maintain our services, such as registration, login, shopping, and customer service.
              </li>
              <li>
                <b>To improve our services</b>: We use your personal information to improve our services, such as analyzing user behavior and optimizing user experience.
              </li>
              <li>
                <b>For marketing purposes</b>: We use your personal information to send you marketing information, such as promotions and product recommendations.
              </li>
              <li>
                <b>To comply with laws and regulations</b>: We use your personal information to comply with applicable laws and regulations.
              </li>
            </ul>
            <h2>2. Sharing of Personal Information</h2>
            <p>
              We will not sell or lease your personal information to third parties. We will only share your personal information in the following cases:
            </p>
            <ul>
              <li>
                <b>With your consent</b>: We may share your personal information with your consent.
              </li>
              <li>
                <b>To comply with laws and regulations</b>: We may share your personal information in the event of a legal obligation or a court order.
              </li>
              <li>
                <b>To protect our rights</b>: We may share your personal information to protect our rights or property.
              </li>
            </ul>
            <h2>3. Protection of Personal Information</h2>
            <p>
              We are committed to protecting the security of your personal information. We have taken a number of security measures to protect your personal information, including:
            </p>
            <ul>
              <li>
                <b>Use of security technologies</b>: We use encryption technology, access control, and other security measures to protect your personal information.
              </li>
              <li>
                <b>Strict internal controls</b>: We have established strict internal control systems to ensure the security of your personal information.
              </li>
              <li>
                <b>Cooperation with third parties</b>: When we cooperate with third parties, we require them to comply with our privacy policy.
              </li>
            </ul>
            <h2>4. Your Rights</h2>
            <p>
              You have the right to access, correct, delete, and withdraw your consent to our collection and use of your personal information. You can exercise your rights in the following ways:
            </p>
            <ul>
              <li>
                <b>Account settings</b>: You can access, correct, and delete your personal information through your account settings page.
                <li>
                  <b>Contact us</b>: You can contact us through our contact information to withdraw your consent to our collection and use of your personal information.
                </li>
            </ul>
            <h2>5. Other Information</h2>
            <ul>
              <li>
                <b>We may update this Privacy Policy from time to time.</b>
              </li>
              <li>
                <b>If you have any questions about this Privacy Policy, please contact us.</b>
              </li>
            </ul>
          </template>

        </div>
      </div>
    </section>

  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
import { getArticleLists } from '@/api/index'
export default {
  components: {
    breadCrumb
  },
  data() {
    return {
      menuList: [
        {
          title: 'Privacy Policy',
          path: ''
        },
      ],
      content: ''
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName;
    }
  },
  mounted() {
    this.GetArticleData()
  },
  methods: {
    GetArticleData() {
      let f = new FormData()
      f.append('Type', '4')
      getArticleLists(f).then(res => {
        if(res.data.Items.length > 0){
          this.content = res.data.Items[0].Content
        }
      })
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
  }
}
</script>